import React, { useEffect } from 'react';
import { useTheme }         from '@chakra-ui/react';
import Cal, { getCalApi }   from '@calcom/embed-react';

const CalcomEmbed = ({ calLink }) => {
  const theme = useTheme();
  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      cal('ui', {
        'theme': theme.initialColorMode,
        'styles': { 'branding': { 'brandColor': theme.colors.brand['500'] } },
        'hideEventTypeDetails': false,
        'layout': 'month_view'
      });
    })();
  }, [])
  return (
    <Cal
      calLink={calLink}
      style={{ width: '100%', height: '100%', overflow: 'scroll' }}
      config={{ layout: 'month_view' }}
    />)
}

export default CalcomEmbed;
